
import { Options, prop, Vue } from "vue-class-component";
import { Field, ErrorMessage } from "vee-validate";
import {
  IMapMeta,
  ITaiwanMap,
} from "../../store/interfaces-from-server/meta/map";
import { IQuery } from "../../store/interfaces-from-server/user/query";
import metaService from "../../services/meta-service";
import { MetaType } from "../../services/service";

@Options({
  name: "Building3DForm",
  components: { Field, ErrorMessage },
  props: {
    selectedMapMeta: prop<IMapMeta>({ required: true }),
    query: prop<IQuery>({ required: true }),
  },
})
export default class Building3DForm extends Vue {
  selectedMapMeta: IMapMeta;
  query: IQuery;

  taiwanMaps: ITaiwanMap[];
  selectedCityMeta?: ITaiwanMap;
  mapMetaLoaded = false;

  mounted() {
    metaService.get(MetaType.taiwanmap).then((metas: ITaiwanMap[]) => {
      this.taiwanMaps = metas.filter((e) => e.country != "臺北市");
      this.mapMetaLoaded = true;
    });
  }

  set selectedCity(id: string) {
    const id_int = parseInt(id);
    this.selectedCityMeta = this.getCityFromId(id_int);
    if (this.selectedCityMeta) {
      this.query.index = this.selectedCityMeta.country;
    }
  }
  get selectedCity() {
    if (this.query.index) {
      this.selectedCityMeta = this.getCityFromName(this.query.index);
      if (this.selectedCityMeta) {
        return this.selectedCityMeta.id.toString();
      }
    }
    return "";
  }

  getCityFromId(id: number) {
    for (let index = 0; index < this.taiwanMaps.length; index++) {
      const meta: ITaiwanMap = this.taiwanMaps[index];
      if (meta.id === id) {
        return meta;
      }
    }
  }
  getCityFromName(name: string) {
    for (let index = 0; index < this.taiwanMaps.length; index++) {
      const meta: ITaiwanMap = this.taiwanMaps[index];
      if (meta.country === name) {
        return meta;
      }
    }
  }

  stringRequired(value: string) {
    if (!value) {
      return "必填";
    }
    return true;
  }
}
