
import { Options, Vue } from "vue-class-component";
import { GroupRole } from "@/store/interfaces-from-server/user/group";

@Options({
  name: "AppNavbar",
  components: {},
  props: {},
})
export default class AppNavbar extends Vue {
  get isLogIned() {
    return this.$store.state.auth.status.loggedIn;
  }
  get isAdmin() {
    return this.$store.state.auth.user?.role === GroupRole.admin;
  }
}
