
import { Options } from "vue-class-component";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { BasicView } from "../../utils/view";
import AuthService from "../../services/auth-service";

@Options({
  name: "LoginView",
  components: { Form, Field, ErrorMessage },
  props: {},
})
export default class LoginView extends BasicView {
  schema = yup.object().shape({
    verificationcode: yup.string().required("Verification code is required!"),
  });
  verificationcode = "";

  mounted() {
    if (!this.isLogIned) {
      this.$router.push({ name: "login" });
    }
  }

  handleValidate() {
    this.loading = true;
    AuthService.validate(this.verificationcode)
      .then(() => {
        this.message = "開通成功";
        this.loading = false;
        setTimeout(() => {
          this.$store.dispatch("auth/update").then(() => {
            this.$emit("update");
          });
        }, 1000);
      })
      .catch(this.errorHandle);
  }
}
