
import { Options, prop, Vue } from "vue-class-component";
import { Form } from "vee-validate";

@Options({
  name: "ComponentsForm",
  components: { Form },
  props: {
    schema: prop<unknown>({ required: true }),
  },
  emits: ["close"],
})
export default class ComponentsForm extends Vue {
  schema: unknown;
}
