import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = {
  key: 0,
  class: "alert alert-danger",
  role: "alert"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StudentTable = _resolveComponent("StudentTable")!
  const _component_TeacherTable = _resolveComponent("TeacherTable")!
  const _component_ProjectTable = _resolveComponent("ProjectTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_StudentTable, {
      onUpdate: _cache[0] || (_cache[0] = 
      (complete) => {
        _ctx.studentComplete = complete;
      }
    ),
      isConfirm: _ctx.isConfirm
    }, null, 8, ["isConfirm"]),
    _createVNode(_component_TeacherTable, {
      onUpdate: _cache[1] || (_cache[1] = 
      (complete) => {
        _ctx.teacherComplete = complete;
      }
    ),
      isConfirm: _ctx.isConfirm
    }, null, 8, ["isConfirm"]),
    _createVNode(_component_ProjectTable, {
      onUpdate: _cache[2] || (_cache[2] = 
      (complete) => {
        _ctx.paperComplete = complete;
      }
    ),
      isConfirm: _ctx.isConfirm
    }, null, 8, ["isConfirm"]),
    _createElementVNode("button", {
      class: _normalizeClass(["btn btn-success btn-lg", {
      disabled: !_ctx.studentComplete || !_ctx.teacherComplete || !_ctx.paperComplete,
    }]),
      onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.confirm && _ctx.confirm(...args)))
    }, " 確認報名 ", 2),
    (_ctx.isConfirm)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: _normalizeClass(["btn btn-danger btn-lg", {
      disabled: !_ctx.studentComplete || !_ctx.teacherComplete || !_ctx.paperComplete,
    }]),
          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.cancel && _ctx.cancel(...args)))
        }, " 取消 ", 2))
      : _createCommentVNode("", true),
    (_ctx.isConfirm)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, "確認報名後無法修改資料"))
      : _createCommentVNode("", true),
    (!_ctx.isConfirm)
      ? (_openBlock(), _createElementBlock("button", {
          key: 2,
          class: "btn btn-primary btn-lg",
          onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.toMap && _ctx.toMap(...args)))
        }, " 預填圖資申請 "))
      : _createCommentVNode("", true),
    _createElementVNode("div", null, [
      (_ctx.message)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.message), 1))
        : _createCommentVNode("", true)
    ])
  ], 64))
}