
import { Options, prop } from "vue-class-component";
import { Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import BasicForm from "../../components/Form.vue";
import {
  IMapMeta,
  MapCategory,
} from "../../store/interfaces-from-server/meta/map";
import { CommonForm } from "../CommonForm";
import metaService from "../../services/meta-service";
import { MetaType, OperationMode } from "../../services/service";
import { IQuery } from "../../store/interfaces-from-server/user/query";
import CadastralMapForm from "./CadastralMapForm.vue";
import ParticularCadastralMapForm from "./ParticularCadastralMapForm.vue";
import SingleMapForm from "./SingleMapForm.vue";
import PictureMapForm from "./PictureMapForm.vue";
import YearMapForm from "./YearMapForm.vue";
import TaiwanMapForm from "./TaiwanMapForm.vue";
import Building3DForm from "./Building3DForm.vue";
import Road3DForm from "./Road3DForm.vue";
import APIForm from "./APIForm.vue";

@Options({
  name: "MapQueryForm",
  components: {
    BasicForm,
    Field,
    ErrorMessage,
    CadastralMapForm,
    ParticularCadastralMapForm,
    SingleMapForm,
    PictureMapForm,
    YearMapForm,
    TaiwanMapForm,
    Building3DForm,
    Road3DForm,
    APIForm,
  },
  props: {
    mode: prop<OperationMode>({ required: true }),
    query: prop<IQuery>({ required: true }),
  },
  emits: ["close", "submit"],
})
export default class MapQueryForm extends CommonForm {
  schema = yup.object().shape({
    type: yup.string().required("Type is required!"),
  });
  mapCategory = MapCategory;
  mapMetas: IMapMeta[];
  mapMetaLoaded = false;
  selectedMapMeta?: IMapMeta = undefined;

  query: IQuery;
  mapsLoaded = false;
  metaLoaded = false;
  metaSelected = false;
  updateTimestamp = 0;

  mounted() {
    metaService.get(MetaType.map).then((metas) => {
      this.mapMetas = metas;
      this.mapMetaLoaded = true;
    });
  }

  set selectedMapType(id: string) {
    const id_int = parseInt(id);
    this.selectedMapMeta = this.getMapMetaFromId(id_int);
    this.metaSelected = false;
    if (this.query.mid) {
      this.query.index = undefined;
      this.query.scale = undefined;
      this.query.coord = undefined;
      this.query.format = undefined;
      this.query.year = undefined;
      this.query.city = undefined;
      this.query.note = undefined;
      this.query.mid = id_int;
    } else {
      this.query.mid = id_int;
    }
    if (this.selectedMapMeta) {
      this.query.type = this.selectedMapMeta.mapName;
      this.query.price = this.selectedMapMeta.price;
    }
    this.updateTimestamp = Date.now();
    this.$nextTick(() => {
      this.metaSelected = true;
    });
  }
  get selectedMapType() {
    if (this.query.mid) {
      this.selectedMapMeta = this.getMapMetaFromId(this.query.mid);
      this.metaSelected = true;
      return this.query.mid.toString();
    }
    return "";
  }

  getMapMetaFromId(id: number) {
    for (let index = 0; index < this.mapMetas.length; index++) {
      const meta: IMapMeta = this.mapMetas[index];
      if (meta.id === id) {
        return meta;
      }
    }
  }

  stringRequired(value: string) {
    if (!value) {
      return "必填";
    }
    return true;
  }
}
