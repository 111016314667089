
import { IQuery } from "../../store/interfaces-from-server/user/query";
import { Options, Vue, prop } from "vue-class-component";
import Modal from "../Modal.vue";
import { OperationMode } from "../../services/service";

@Options({
  name: "MapTable",
  components: { Modal },
  props: { isShow: prop<boolean>({}) },
  emits: ["update"],
})
export default class MapTable extends Vue {
  modalShow = false;
  isShow: boolean;
  modes = OperationMode;

  MAX_PRICE = 30000;

  get isValid() {
    const _complete = this.totalPrice < this.MAX_PRICE;
    this.$emit("update", _complete);
    return _complete;
  }

  get totalPrice() {
    let total = 0;
    if (this.queries) {
      for (let index = 0; index < this.queries.length; index++) {
        const query = this.queries[index];
        total += query.price;
      }
    }
    return total;
  }

  get queries() {
    if (this.$store.state.auth.user) {
      let qids = this.$store.state.auth.user.qids;
      if (qids) {
        qids.sort((a, b) => a.mid - b.mid);
      }
      return qids;
    } else {
      return undefined;
    }
  }

  mapHandler(mode: OperationMode, map?: IQuery) {
    this.modalShow = true;
    this.$router.push({
      name: "query",
      query: {
        mode: mode,
        qid: map?.qid,
      },
    });
  }

  closeModal() {
    this.modalShow = false;
    this.$store.dispatch("auth/update");
    this.$router.push({
      name: "map",
      query: { t: Date.now() },
    });
  }
}
