import { AxiosError } from "axios";
import { Vue } from "vue-class-component";

export class BasicView extends Vue {
  message = "";
  loading = false;

  get isLogIned() {
    return this.$store.state.auth.status.loggedIn;
  }

  errorHandle(error: AxiosError) {
    console.log(error);
    this.message =
      // @ts-expect-error: response is hard to define type
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    if (error.response && error.response.status == 403) {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    }
    this.loading = false;
  }
}
