import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "alert alert-danger",
  role: "alert"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MapTable = _resolveComponent("MapTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_MapTable, {
      onUpdate: _cache[0] || (_cache[0] = 
      (complete) => {
        _ctx.mapValidate = complete;
      }
    ),
      isShow: false
    }),
    _createElementVNode("button", {
      class: _normalizeClass(["btn btn-success btn-lg", { disabled: !_ctx.mapValidate }]),
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.confirm && _ctx.confirm(...args)))
    }, _toDisplayString(_ctx.isRegistered ? "確認送出申請" : "返回報名"), 3),
    (_ctx.isRegistered)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, "只能申請一次且送出申請後無法修改資料"))
      : _createCommentVNode("", true),
    _createElementVNode("div", null, [
      (_ctx.message)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.message), 1))
        : _createCommentVNode("", true)
    ])
  ], 64))
}