
import { BasicView } from "../../utils/view";
import { Options } from "vue-class-component";
import Modal from "../../components/Modal.vue";
import MapTable from "../../components/map/MapTable.vue";
import GroupService from "../../services/group-service";
import { GroupStatus } from "../../store/interfaces-from-server/user/group";
@Options({
  name: "MapView",
  components: { Modal, MapTable },
  props: {},
  emits: ["update", "to"],
})
export default class MapView extends BasicView {
  mapValidate = false;

  get isRegistered() {
    if (this.$store.state.auth.user) {
      return this.$store.state.auth.user.status === GroupStatus.registered;
    } else {
      return false;
    }
  }

  mounted() {
    if (!this.isLogIned) {
      this.$router.push({ name: "login" });
    }
    const user = this.$store.state.auth.user;
    if (
      user?.status !== GroupStatus.registered &&
      user?.status !== GroupStatus.validated
    ) {
      this.$router.push({ name: "home", query: { t: Date.now() } });
    }
  }

  confirm() {
    if (this.isRegistered) {
      this.updateStatus(GroupStatus.maprequested);
    } else {
      this.$emit("to", "group");
    }
  }

  updateStatus(status: GroupStatus) {
    GroupService.updateStatus(status)
      .then(() => {
        this.$store.dispatch("auth/update").then(() => {
          this.$emit("update");
        });
      })
      .catch(this.errorHandle);
  }
}
