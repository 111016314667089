import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    children: [
      {
        path: "/validate",
        name: "validate",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/auth/ValidationView.vue"
          ),
      },
      {
        path: "group",
        name: "group",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/signup/GroupView.vue"
          ),
        children: [
          {
            path: "student",
            name: "student",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/signup/StudentView.vue"
              ),
          },
          {
            path: "teacher",
            name: "teacher",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/signup/TeacherView.vue"
              ),
          },
          {
            path: "project",
            name: "project",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/signup/ProjectView.vue"
              ),
          },
        ],
      },
      {
        path: "map",
        name: "map",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/map/MapView.vue"),
        children: [
          {
            path: "query",
            name: "query",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/map/MapQueryView.vue"
              ),
          },
        ],
      },
      {
        path: "affidavit",
        name: "affidavit",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/affidavit/AffidavitView.vue"
          ),
      },
      {
        path: "report",
        name: "report",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/report/ReportView.vue"
          ),
      },
      {
        path: "end",
        name: "end",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/end/EndView.vue"),
      },
    ],
  },
  {
    path: "/logout",
    name: "logout",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/auth/LogoutView.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/auth/LoginView.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/auth/RegisterView.vue"),
  },
  {
    path: "/download",
    name: "download",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/admin/DownloadView.vue"),
  },
  {
    path: "/groupstatus",
    name: "groupstatus",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/admin/GroupStatusView.vue"
      ),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
