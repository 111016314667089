
import { Options } from "vue-class-component";
import TeacherForm from "../../components/signup/form/TeacherForm.vue";
import { ITeacher } from "../../store/interfaces-from-server/user/member";
import { BasicView } from "../../utils/view";
import { OperationMode } from "../../services/service";
import { Teacher } from "../../store/classes/member";
import MemberService from "../../services/member-service";

@Options({
  name: "TeacherView",
  components: { TeacherForm },
  props: {},
})
export default class TeacherView extends BasicView {
  formData: {
    mode: OperationMode;
    teacher: ITeacher;
  };

  loaded = false;

  created(): void {
    if (!this.isLogIned) {
      this.$router.push({ name: "login" });
    }
    const mode = this.$route.query.mode as OperationMode;

    this.formData = {
      mode: mode,
      teacher: new Teacher(),
    };
    if (mode === "modify" || mode === "delete") {
      const tid = parseInt(this.$route.query.tid as string);
      this.formData.teacher.tid = tid;
      MemberService.Teacher(OperationMode.getid, this.formData.teacher)
        .then((teacher) => {
          this.formData.teacher = teacher;
          this.loaded = true;
        })
        .catch(this.errorHandle);
    } else {
      this.loaded = true;
    }
  }

  handleSubmit() {
    MemberService.Teacher(this.formData.mode, this.formData.teacher)
      .then(() => this.$emit("close"))
      .catch(this.errorHandle);
    this.loaded = false;
  }
}
