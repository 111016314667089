
import { BasicView } from "../../utils/view";
import { Options } from "vue-class-component";
import Modal from "../../components/Modal.vue";
import GroupTable from "../../components/group/GroupTable.vue";
import {
  GroupRole,
  GroupStatus,
} from "../../store/interfaces-from-server/user/group";
import adminService from "@/services/admin-service";

@Options({
  name: "GroupStatusView",
  components: { Modal, GroupTable },
  props: {},
  emits: ["update", "to"],
})
export default class GroupStatusView extends BasicView {
  category = "highschool";
  categories = [
    { key: "college", name: "大專院校組" },
    { key: "highschool", name: "高中職組" },
  ];
  statuses = [
    { name: GroupStatus.validated, nameCN: "已開通帳號", selected: false },
    { name: GroupStatus.filledin, nameCN: "已填寫報名資料", selected: false },
    { name: GroupStatus.registered, nameCN: "已完成報名", selected: false },
    {
      name: GroupStatus.maprequested,
      nameCN: "已送出圖資申請",
      selected: false,
    },
    {
      name: GroupStatus.affidavitsigned,
      nameCN: "已繳交圖資申請同意書",
      selected: false,
    },
    {
      name: GroupStatus.reportuploaded,
      nameCN: "已繳交成果報告",
      selected: false,
    },
  ];
  categorySelected = true;

  get isAdmin() {
    if (this.$store.state.auth.user) {
      return this.$store.state.auth.user.role === GroupRole.admin;
    } else {
      return false;
    }
  }

  get filteredStatus() {
    return this.statuses.filter((status) => status.selected);
  }

  mounted() {
    if (!this.isLogIned) {
      this.$router.push({ name: "login" });
    }
    if (!this.isAdmin) {
      this.$router.push({ name: "home" });
    }
  }

  onSelectionChange() {
    this.categorySelected = false;
    this.$nextTick(() => {
      this.categorySelected = true;
    });
  }

  getGroups() {
    adminService
      .getGroupZip()
      .then((link) => {
        link.click();
        URL.revokeObjectURL(link.href);
      })
      .catch(this.errorHandle);
  }
}
