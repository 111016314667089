import { IQuery } from "@/store/interfaces-from-server/user/query";
import { OperationMode, RESTfulService } from "./service";

const API_URL = "/api";
const API_SERVICE = `${API_URL}/group`;

class QueryMapService {
  async QueryMap(mode: OperationMode, query: IQuery) {
    const API_KEYWORD = "query";
    const API_URI = `${API_SERVICE}/${API_KEYWORD}`;
    return RESTfulService(API_URI, mode, query?.qid, query);
  }
}

export default new QueryMapService();
