import { IStudent, ITeacher } from "@/store/interfaces-from-server/user/member";
import { IProject } from "@/store/interfaces-from-server/user/project";
import { IQuery } from "@/store/interfaces-from-server/user/query";
import axios from "axios";

export enum OperationMode {
  get = "get",
  getid = "getid",
  add = "add",
  modify = "modify",
  delete = "delete",
}

export enum MetaType {
  map = "map",
  api = "api",
  taiwanmap = "taiwanmap",
  highschool = "highschool",
  college = "college",
}

export enum StoreName {
  user = "user",
  selection = "selection",
  map = "map",
  api = "api",
  taiwanmap = "taiwanmap",
  highschool = "highschool",
  college = "college",
}

export function RESTfulService(
  uri: string,
  mode: OperationMode,
  objectId?: number,
  object?: IStudent | ITeacher | IProject | IQuery
) {
  switch (mode) {
    case OperationMode.get:
      return axios.get(uri).then((response) => {
        return response.data;
      });
    case OperationMode.getid:
      return axios.get(uri + `/${objectId}`).then((response) => {
        return response.data;
      });
    case OperationMode.add:
      return axios.post(uri, object).then((response) => {
        return response.data;
      });
    case OperationMode.modify:
      return axios.put(uri + `/${objectId}`, object).then((response) => {
        return response.data;
      });
    case OperationMode.delete:
      return axios.delete(uri + `/${objectId}`).then((response) => {
        return response.data;
      });
  }
}
