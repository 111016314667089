
import { Options } from "vue-class-component";
import GroupService from "@/services/group-service";
import {
  GroupStatus,
  IGroup,
} from "../store/interfaces-from-server/user/group";
import { BasicView } from "@/utils/view";

@Options({
  name: "HomeView",
  components: {},
  props: {},
})
export default class HomeView extends BasicView {
  progress: { [name: string]: boolean } = {
    created: false,
    validated: false,
    filledin: false,
    registered: false,
    maprequested: false,
    affidavitsigned: false,
    reportuploaded: false,
  };

  mounted() {
    GroupService.getGroup()
      .then((group) => {
        this.progress[group.status as string] = true;
        this.reloadRouter(group);
      })
      .catch(this.errorHandle);
  }

  update() {
    this.resetProgress();
    const group = this.$store.state.auth.user;
    if (group) {
      this.progress[group.status] = true;
      this.reloadRouter(group);
    }
  }

  resetProgress() {
    Object.keys(this.progress).forEach((key) => {
      this.progress[key] = false;
    });
  }

  toPage(page: string) {
    this.$router.push({ name: page });
  }

  reloadRouter(group: IGroup) {
    if (group.status === GroupStatus.created) {
      this.$router.push({ name: "validate", query: { t: Date.now() } });
    } else if (
      group.status === GroupStatus.validated ||
      group.status === GroupStatus.filledin
    ) {
      this.$router.push({ name: "group", query: { t: Date.now() } });
    } else if (group.status === GroupStatus.registered) {
      this.$router.push({ name: "map", query: { t: Date.now() } });
    } else if (group.status === GroupStatus.maprequested) {
      this.$router.push({ name: "affidavit", query: { t: Date.now() } });
    } else if (group.status === GroupStatus.affidavitsigned) {
      this.$router.push({ name: "report", query: { t: Date.now() } });
    } else if (group.status === GroupStatus.reportuploaded) {
      this.$router.push({ name: "end", query: { t: Date.now() } });
    }
  }
}
