
import { BasicView } from "../../utils/view";
import { Options } from "vue-class-component";
import Modal from "../../components/Modal.vue";
import MapTable from "../../components/map/MapTable.vue";
import { GroupRole } from "../../store/interfaces-from-server/user/group";
import adminService from "../../services/admin-service";

@Options({
  name: "DownloadView",
  components: { Modal, MapTable },
  props: {},
  emits: ["update", "to"],
})
export default class DownloadView extends BasicView {
  uploaded = false;
  docChecked = false;
  fileInput: File | null = null;

  get isAdmin() {
    if (this.$store.state.auth.user) {
      return this.$store.state.auth.user.role === GroupRole.admin;
    } else {
      return false;
    }
  }

  mounted() {
    if (!this.isLogIned) {
      this.$router.push({ name: "login" });
    }
    if (!this.isAdmin) {
      this.$router.push({ name: "home" });
    }
  }

  getQueryZip() {
    adminService
      .getQueryZip()
      .then((link) => {
        link.click();
        URL.revokeObjectURL(link.href);
      })
      .catch(this.errorHandle);
  }
}
