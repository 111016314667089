
import { IStudent } from "../../../store/interfaces-from-server/user/member";
import { Options } from "vue-class-component";
import Modal from "../../Modal.vue";
import MemberService from "../../../services/member-service";
import { BasicView } from "../../../utils/view";
import { OperationMode } from "../../../services/service";

@Options({
  name: "StudentTable",
  components: { Modal },
  props: { isConfirm: Boolean },
  emits: ["update"],
})
export default class StudentTable extends BasicView {
  modalShow = false;
  isConfirm: boolean;
  modes = OperationMode;

  isStudentValid() {
    return this.students && this.students.length > 0;
  }

  get complete() {
    const _complete =
      !!this.students && this.students.length > 0 && !!this.existLeader;
    this.$emit("update", _complete);
    return _complete;
  }

  get existLeader() {
    let flag = false;
    this.students?.forEach((student) => {
      if (student.leader === true) {
        flag = true;
      }
    });
    return flag;
  }

  get students() {
    if (this.$store.state.auth.user) {
      return this.$store.state.auth.user.sids;
    } else {
      return undefined;
    }
  }

  studentHandler(mode: OperationMode, student?: IStudent) {
    this.modalShow = true;
    this.$router.push({
      name: "student",
      query: {
        mode: mode,
        sid: student?.sid,
      },
    });
  }

  setLeader(student: IStudent) {
    MemberService.updateLeader(student.sid)
      .then(() => {
        this.closeModal();
      })
      .catch(this.errorHandle);
  }

  closeModal() {
    this.modalShow = false;
    this.$store.dispatch("auth/update");
    this.$router.push({
      name: "group",
      query: { t: Date.now() },
    });
  }
}
