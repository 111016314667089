import { IQuery } from "../interfaces-from-server/user/query";

export class QueryMap implements IQuery {
  qid: number;
  gid: number;
  type: string;
  mid: number;
  index: string;
  indexExtra: string;
  scale: string;
  coord: string;
  format: string;
  price: number;
  year: string;
  city: string;
  note: string;
}

export function QueryMapFactory(_map: QueryMap) {
  const map = new QueryMap();
  map.qid = _map.qid;
  map.gid = _map.gid;
  map.type = _map.type;
  map.index = _map.index;
  map.indexExtra = _map.indexExtra;
  map.scale = _map.scale;
  map.coord = _map.coord;
  map.format = _map.format;
  map.price = _map.price;
  map.year = _map.year;
  map.city = _map.city;
  map.note = _map.note;
  return map;
}
