
import { IProject } from "../../../store/interfaces-from-server/user/project";
import { Options } from "vue-class-component";
import Modal from "../../Modal.vue";
import { BasicView } from "../../../utils/view";
import { OperationMode } from "../../../services/service";

@Options({
  name: "ProjectTable",
  components: { Modal },
  props: { isConfirm: Boolean },
  emits: ["update"],
})
export default class ProjectTable extends BasicView {
  modalShow = false;
  isConfirm: boolean;
  modes = OperationMode;

  get complete() {
    const _complete = !!this.project;
    this.$emit("update", _complete);
    return _complete;
  }

  get project() {
    const user = this.$store.state.auth.user;
    if (user && user.pid !== null) {
      return user.pid;
    } else {
      return undefined;
    }
  }

  projectHandler(mode: OperationMode, project?: IProject) {
    this.modalShow = true;
    this.$router.push({
      name: "project",
      query: {
        mode: mode,
        pid: project?.pid,
      },
    });
  }

  closeModal() {
    this.modalShow = false;
    this.$store.dispatch("auth/update");
    this.$router.push({
      name: "group",
      query: { t: Date.now() },
    });
  }
}
