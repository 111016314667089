import { IStudent, ITeacher } from "./member";
import { IProject } from "./project";
import { IQuery } from "./query";

export enum GroupCategory {
  highschool = "highschool",
  college = "college",
}

export enum GroupStatus {
  created = "created",
  validated = "validated",
  filledin = "filledin",
  registered = "registered",
  maprequested = "maprequested",
  affidavitsigned = "affidavitsigned",
  reportuploaded = "reportuploaded",
}

export enum GroupRole {
  user = "user",
  admin = "admin",
}

export interface IGroup {
  gid: number;
  email: string;
  password: string;
  verificationcode: string;
  category: GroupCategory;
  status: GroupStatus;
  role: GroupRole;
  sids: IStudent[] | null;
  qids: IQuery[] | null;
  tid: ITeacher | null;
  pid: IProject | null;
  affidavitSubmit: Date | null;
}
