
import { Vue } from "vue-class-component";

export default class LoginView extends Vue {
  get isLogIned() {
    return this.$store.state.auth.status.loggedIn;
  }

  get user() {
    return this.$store.state.auth.user;
  }

  mounted() {
    if (!this.isLogIned) {
      this.$router.push("/login");
    }
  }

  logOut() {
    this.$store.dispatch("auth/logout");
    this.$store.dispatch("selection/logout");
    this.$router.push("/login");
  }

  cancel() {
    this.$router.push("/");
  }
}
