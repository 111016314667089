
import { BasicView } from "@/utils/view";
import { Options } from "vue-class-component";
import Modal from "@/components/Modal.vue";
import MapTable from "@/components/map/MapTable.vue";
import GroupService from "@/services/group-service";
import { GroupStatus } from "@/store/interfaces-from-server/user/group";

@Options({
  name: "ReportView",
  components: { Modal, MapTable },
  props: {},
  emits: ["update", "to"],
})
export default class ReportView extends BasicView {
  get isRollBackPossible() {
    return true;
  }

  get isReportUploaded() {
    const user = this.$store.state.auth.user;
    if (user) {
      return user.status === GroupStatus.reportuploaded;
    } else {
      return false;
    }
  }

  mounted() {
    if (!this.isLogIned) {
      this.$router.push({ name: "login" });
    }
    if (!this.isReportUploaded) {
      this.$router.push({ name: "home", query: { t: Date.now() } });
    }
  }

  rollback() {
    if (this.isRollBackPossible) {
      this.updateStatus(GroupStatus.affidavitsigned);
    }
  }

  updateStatus(status: GroupStatus) {
    GroupService.updateStatus(status)
      .then(() => {
        this.$store.dispatch("auth/update").then(() => {
          this.$emit("update");
        });
      })
      .catch(this.errorHandle);
  }
}
