
import { Options, prop, Vue } from "vue-class-component";
import { Field, ErrorMessage } from "vee-validate";
import { ITownItem } from "@/store/interfaces/nlsc";
import metaService from "@/services/meta-service";

@Options({
  name: "TownNLSCField",
  components: { Field, ErrorMessage },
  props: {
    countyCode: prop<string>({ required: true }),
    townCode: prop<string>({ required: true }),
  },
  emits: ["townSelected"],
})
export default class TownNLSCField extends Vue {
  townList?: ITownItem[] | null;
  townListLoaded = false;
  townCode: string;
  countyCode: string;

  mounted() {
    if (this.countyCode) {
      metaService.getListTown(this.countyCode[0]).then((townItems) => {
        this.townList = townItems;
        this.townListLoaded = true;
      });
    }
  }

  townSelected = false;
  set selectedTown(code: string) {
    this.$emit("townSelected", code);
  }
  get selectedTown() {
    // console.log(`county Field code: ${this.countyCode}`);
    return this.townCode as string;
  }
}
