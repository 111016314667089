import { IStudent, ITeacher } from "@/store/interfaces-from-server/user/member";
import axios from "axios";
import { OperationMode, RESTfulService } from "./service";

const API_URL = "/api";
const API_SERVICE = `${API_URL}/group`;

class MemberService {
  async Student(mode: OperationMode, student?: IStudent) {
    const API_KEYWORD = "student";
    const API_URI = `${API_SERVICE}/${API_KEYWORD}`;
    return RESTfulService(API_URI, mode, student?.sid, student);
  }

  async Teacher(mode: OperationMode, teacher?: ITeacher) {
    const API_KEYWORD = "teacher";
    const API_URI = `${API_SERVICE}/${API_KEYWORD}`;
    return RESTfulService(API_URI, mode, teacher?.tid, teacher);
  }

  async updateLeader(sid: number) {
    return axios.put(API_SERVICE + `/student/leader/${sid}`);
  }
}

export default new MemberService();
