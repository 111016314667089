import { BasicView } from "../utils/view";
import { OperationMode } from "../services/service";

export class CommonForm extends BasicView {
  mode: OperationMode;

  get isDelete() {
    return this.mode === OperationMode.delete;
  }

  get btnText() {
    if (this.mode === OperationMode.add) {
      return "新增";
    } else if (this.mode === OperationMode.modify) {
      return "修改";
    } else if (this.mode === OperationMode.delete) {
      return "刪除";
    }
    return "";
  }

  get btnClass() {
    if (this.mode === OperationMode.add) {
      return "btn-primary";
    } else if (this.mode === OperationMode.modify) {
      return "btn-primary";
    } else if (this.mode === OperationMode.delete) {
      return "btn-danger";
    }
    return "";
  }
}
