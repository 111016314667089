import { ActionContext } from "vuex";
import { RootState } from "../vuex";

const clientSelectionString = localStorage.getItem("selection");
const clientSelection = clientSelectionString
  ? JSON.parse(clientSelectionString)
  : undefined;

type ActionContextType = ActionContext<SelectionState, RootState>;

export interface SelectionState {
  school?: string;
}

const initialState: SelectionState = clientSelection
  ? clientSelection
  : { school: undefined };

export const selection = {
  namespaced: true,
  state: initialState,
  actions: {
    update({ commit }: ActionContextType, school: string) {
      commit("updateSchool", school);
    },
    logout({ commit }: ActionContextType) {
      commit("logoutSchool");
    },
  },
  mutations: {
    updateSchool(state: SelectionState, school: string) {
      const _selection = { school: school };
      localStorage.setItem("selection", JSON.stringify(_selection));
      state.school = school;
    },
    logoutSchool(state: SelectionState) {
      state.school = undefined;
    },
  },
};
