import axios from "axios";

const API_URL = "/api";
const API_SERVICE = `${API_URL}/admin`;

class AdminService {
  async getQueryZip() {
    return axios
      .get(API_SERVICE + `/queryzip`, {
        responseType: "blob",
      })
      .then((response) => {
        const filename = (
          response.headers["content-disposition"] || "圖資申請清單.zip"
        ).split("filename=")[1];
        const blob = new Blob([response.data], { type: "application/zip" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        return link;
      });
  }

  async getGroups() {
    return axios.get(API_SERVICE + `/groups`).then((response) => {
      return response.data;
    });
  }

  async getGroupZip() {
    return axios
      .get(API_SERVICE + `/groupzip`, {
        responseType: "blob",
      })
      .then((response) => {
        const filename = (
          response.headers["content-disposition"] || "隊伍總表.zip"
        ).split("filename=")[1];
        const blob = new Blob([response.data], { type: "application/zip" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        return link;
      });
  }
}

export default new AdminService();
