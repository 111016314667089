
import { Options, prop, Vue } from "vue-class-component";
import { Field, ErrorMessage } from "vee-validate";
import {
  IMapMeta,
  ITaiwanMap,
} from "../../store/interfaces-from-server/meta/map";
import { IQuery } from "../../store/interfaces-from-server/user/query";
import metaService from "../../services/meta-service";
import { MetaType } from "../../services/service";

@Options({
  name: "TaiwanMapFrom",
  components: { Field, ErrorMessage },
  props: {
    selectedMapMeta: prop<IMapMeta>({ required: true }),
    query: prop<IQuery>({ required: true }),
  },
})
export default class TaiwanMapFrom extends Vue {
  selectedMapMeta: IMapMeta;
  query: IQuery;

  taiwanMaps: ITaiwanMap[];
  selectedCityMeta?: ITaiwanMap;
  mapMetaLoaded = false;
  citySelected = false;
  currentPrice = 0;

  mounted() {
    metaService.get(MetaType.taiwanmap).then((metas) => {
      this.taiwanMaps = metas;
      this.mapMetaLoaded = true;
    });
    this.query.format = "SHP";
  }

  set selectedCity(id: string) {
    const id_int = parseInt(id);
    this.selectedCityMeta = this.getCityFromId(id_int);
    if (this.selectedCityMeta) {
      this.query.index = this.selectedCityMeta.country;
      this.updatePrice();
      this.query.price = this.currentPrice;
    }
    this.citySelected = true;
  }
  get selectedCity() {
    if (this.query.index) {
      this.selectedCityMeta = this.getCityFromName(this.query.index);
      this.citySelected = true;
      if (this.selectedCityMeta) {
        this.updatePrice();
        return this.selectedCityMeta.id.toString();
      }
    }
    return "";
  }

  updatePrice() {
    if (this.selectedCityMeta) {
      this.currentPrice =
        this.selectedCityMeta[this.selectedMapMeta.taiwancatrgory];
    }
  }

  getCityFromId(id: number) {
    for (let index = 0; index < this.taiwanMaps.length; index++) {
      const meta: ITaiwanMap = this.taiwanMaps[index];
      if (meta.id === id) {
        return meta;
      }
    }
  }
  getCityFromName(name: string) {
    for (let index = 0; index < this.taiwanMaps.length; index++) {
      const meta: ITaiwanMap = this.taiwanMaps[index];
      if (meta.country === name) {
        return meta;
      }
    }
  }

  stringRequired(value: string) {
    if (!value) {
      return "必填";
    }
    return true;
  }
}
