
import { Options, prop, Vue } from "vue-class-component";
import { Field, ErrorMessage } from "vee-validate";
import { ICountyItem } from "@/store/interfaces/nlsc";
import metaService from "@/services/meta-service";

@Options({
  name: "CountyNLSCField",
  components: { Field, ErrorMessage },
  props: {
    countyCode: prop<string>({ required: true }),
  },
  emits: ["countySelected"],
})
export default class CountyNLSCField extends Vue {
  countyList?: ICountyItem[] | null;
  countyListLoaded = false;
  countyCode: string;

  mounted() {
    metaService.getListCounty().then((countyItems) => {
      this.countyList = countyItems;
      this.countyListLoaded = true;
    });
  }

  countySelected = false;
  set selectedCounty(code: string) {
    this.$emit("countySelected", code);
  }
  get selectedCounty() {
    // console.log(`county Field code: ${this.countyCode}`);
    if (this.countyCode) {
      return this.countyCode[0] as string;
    }
    return "";
  }
}
