import { IGroup } from "../interfaces-from-server/user/group";
import { IProject } from "../interfaces-from-server/user/project";

export interface UserState {
  status: {
    loggedIn: boolean;
  };
  user?: IGroup;
}

export class Project implements IProject {
  pid: number;
  pcode: string;
  title: string;
  abstract: string;
  ytlink: string;
  reportuploaded: boolean;
}

export function ProjectClassFactory(_project: Project) {
  const project = new Project();
  project.pid = _project.pid;
  project.pcode = _project.pcode;
  project.title = _project.title;
  project.abstract = _project.abstract;
  project.ytlink = _project.ytlink;
  return project;
}
