
import { Options, prop } from "vue-class-component";
import { Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { ITeacher } from "../../../store/interfaces-from-server/user/member";
import { ISchool } from "../../../store/interfaces-from-server/meta/school";
import BasicForm from "../../Form.vue";
import MetaService from "../../../services/meta-service";
import { MetaType, OperationMode } from "../../../services/service";
import { GroupCategory } from "../../../store/interfaces-from-server/user/group";
import { CommonForm } from "../../CommonForm";

@Options({
  name: "TeacherForm",
  components: { BasicForm, Field, ErrorMessage },
  props: {
    mode: prop<OperationMode>({ required: true }),
    teacher: prop<ITeacher>({ required: true }),
  },
  emits: ["close", "submit"],
})
export default class TeacherForm extends CommonForm {
  schema = yup.object().shape({
    email: yup.string().required("Email is required!"),
    name: yup.string().required("Name is required!"),
    phone: yup.string().required("Phone is required!"),
    school: yup.string().required("School is required!"),
    subject: yup.string().required("Subject is required!"),
  });

  teacher: ITeacher;
  schools?: ISchool[] = undefined;
  schoolsLoaded = false;

  schoolNameCompare(front: ISchool, back: ISchool) {
    return front.name.localeCompare(back.name, "zh-hant");
  }

  async mounted() {
    const user = this.$store.state.auth.user;
    switch (user?.category) {
      case GroupCategory.college:
        this.schools = await MetaService.get(MetaType.college);
        break;
      case GroupCategory.highschool:
        this.schools = await MetaService.get(MetaType.highschool);
        break;
    }
    if (this.schools) this.schools.sort(this.schoolNameCompare);
    this.schoolsLoaded = true;
  }

  set school(school: string) {
    this.teacher.school = school;
    this.$store.dispatch("selection/update", school);
  }
  get school() {
    if (!this.teacher.school) {
      const selection = this.$store.state.selection;
      if (selection && selection.school) {
        this.teacher.school = selection.school;
      }
    }
    return this.teacher.school as string;
  }
}
