
import { ITeacher } from "../../../store/interfaces-from-server/user/member";
import { Options } from "vue-class-component";
import Modal from "../../Modal.vue";
import { BasicView } from "../../../utils/view";
import { OperationMode } from "../../../services/service";

@Options({
  name: "TeacherTable",
  components: { Modal },
  props: { isConfirm: Boolean },
  emits: ["update"],
})
export default class TeacherTable extends BasicView {
  modalShow = false;
  isConfirm: boolean;
  modes = OperationMode;

  get complete() {
    const _complete = !!this.teacher;
    this.$emit("update", _complete);
    return _complete;
  }

  get teacher() {
    const user = this.$store.state.auth.user;
    if (user && user.tid !== null) {
      return user.tid;
    } else {
      return undefined;
    }
  }

  teacherHandler(mode: OperationMode, teacher?: ITeacher) {
    this.modalShow = true;
    this.$router.push({
      name: "teacher",
      query: {
        mode: mode,
        tid: teacher?.tid,
      },
    });
  }

  closeModal() {
    this.modalShow = false;
    this.$store.dispatch("auth/update");
    this.$router.push({
      name: "group",
      query: { t: Date.now() },
    });
  }
}
