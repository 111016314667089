
import { OperationMode } from "../../services/service";
import { QueryMap } from "../../store/classes/map";
import { IQuery } from "../../store/interfaces-from-server/user/query";
import { Options } from "vue-class-component";
import MapQueryForm from "../../components/map/MapQueryForm.vue";
import QueryMapService from "../../services/map-service";
import { BasicView } from "../../utils/view";

@Options({
  name: "MapQueryView",
  components: { MapQueryForm },
  props: {},
  emits: ["close"],
})
export default class MapQueryView extends BasicView {
  formData: {
    mode: OperationMode;
    query: IQuery;
  };

  loaded = false;

  mounted() {
    if (!this.isLogIned) {
      this.$router.push({ name: "login" });
    }
    const mode = this.$route.query.mode as OperationMode;
    this.formData = {
      mode: mode,
      query: new QueryMap(),
    };
    if (mode === OperationMode.modify || mode === OperationMode.delete) {
      const qid = parseInt(this.$route.query.qid as string);
      this.formData.query.qid = qid;
      QueryMapService.QueryMap(OperationMode.getid, this.formData.query)
        .then((queries) => {
          if (queries.length > 0) {
            this.formData.query = queries[0];
            this.loaded = true;
          }
        })
        .catch(this.errorHandle);
    } else {
      this.loaded = true;
    }
  }

  handleSubmit() {
    QueryMapService.QueryMap(this.formData.mode, this.formData.query)
      .then(() => this.$emit("close"))
      .catch(this.errorHandle);
  }
}
