
import { Options, prop } from "vue-class-component";
import { Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { IProject } from "../../../store/interfaces-from-server/user/project";
import BasicForm from "../../Form.vue";
import { OperationMode } from "@/services/service";
import { CommonForm } from "../../CommonForm";

@Options({
  name: "ProjectForm",
  components: { BasicForm, Field, ErrorMessage },
  props: {
    mode: prop<OperationMode>({ required: true }),
    project: prop<IProject>({ required: true }),
  },
  emits: ["close", "submit"],
})
export default class ProjectForm extends CommonForm {
  schema = yup.object().shape({
    title: yup.string().required("Title is required!"),
    abstract: yup.string().required("Abstract is required!").max(200),
  });
  project: IProject;
}
