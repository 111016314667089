export enum MapCategory {
  singlemap = "singlemap",
  cadastralmap = "cadastralmap",
  particularcadastralmap = "particularcadastralmap",
  picturemap = "picturemap",
  yearmap = "yearmap",
  taiwanmap = "taiwanmap",
  building3d = "building3d",
  road3d = "road3d",
  api = "api",
}

export enum TaiwanMapCategory {
  traffic = "traffic",
  road_rail = "road_rail",
  building_area = "building_area",
  landmark = "landmark",
  water_system = "water_system",
}

export interface ITaiwanMap {
  id: number;
  country: string;
  traffic: number;
  road_rail: number;
  building_area: number;
  landmark: number;
  water_system: number;
}

export interface IMapMeta {
  id: number;
  mapName: string;
  hasIndex: boolean;
  indexName: string;
  hasCoord: boolean;
  needCoord: boolean;
  hasScale: boolean;
  fixScale: string;
  hasFormat: boolean;
  fixFormat: string;
  hasCountry: boolean;
  hasYear: boolean;
  unitName: string;
  price: number;
  category: MapCategory;
  taiwancatrgory: TaiwanMapCategory;
  created_at: string;
  updated_at: string;
}

export interface IApi {
  id: number;
  name: string;
  apitype: string;
  apicode: string;
  encode: string;
}
