
import { BasicView } from "@/utils/view";
import { Options } from "vue-class-component";
import Modal from "@/components/Modal.vue";
import MapTable from "@/components/map/MapTable.vue";
import GroupService from "@/services/group-service";
import FileService from "@/services/file-service";
import { GroupStatus } from "@/store/interfaces-from-server/user/group";
import { FileType } from "@/store/interfaces-from-server/meta/file";
import { OperationMode } from "@/services/service";
import { IProject } from "@/store/interfaces-from-server/user/project";

@Options({
  name: "ReportView",
  components: { Modal, MapTable },
  props: {},
  emits: ["update", "to"],
})
export default class ReportView extends BasicView {
  uploaded = false;
  linkUpdated = false;
  docChecked = false;
  fileInput: File | null = null;
  projectLoaded = false;
  project?: IProject;
  loading = false;

  get isRollBackPossible() {
    return true;
  }

  get isAffidavitSigned() {
    const user = this.$store.state.auth.user;
    if (user) {
      return user.status === GroupStatus.affidavitsigned;
    } else {
      return false;
    }
  }

  get isUploaded() {
    const user = this.$store.state.auth.user;
    if (user) {
      return user.pid?.reportuploaded;
    } else {
      return false;
    }
  }

  get isLinkUploaded() {
    const user = this.$store.state.auth.user;
    if (user) {
      return user.pid?.ytlink;
    } else {
      return false;
    }
  }

  onFileChange(event: Event) {
    if (event.target) {
      // @ts-expect-error: event target type not defined
      this.fileInput = event.target.files[0] as File;
    }
  }

  mounted() {
    if (!this.isLogIned) {
      this.$router.push({ name: "login" });
    }
    if (!this.isAffidavitSigned) {
      this.$router.push({ name: "home", query: { t: Date.now() } });
    }
    GroupService.Project(OperationMode.get, undefined)
      .then((project) => {
        this.project = project;
        this.projectLoaded = true;
      })
      .catch(this.errorHandle);
  }

  uploadReport() {
    this.loading = true;
    if (this.fileInput) {
      const formData = new FormData();
      formData.append("files", this.fileInput);
      FileService.postFile(FileType.report, formData)
        .then((response) => {
          if (response.ok) {
            this.$store.dispatch("auth/update").then(() => {
              this.$emit("update");
            });
            this.uploaded = true;
            setTimeout(() => {
              this.$router.go(0);
            }, 3000);
          } else if (response.status === 500) {
            this.message = "伺服器錯誤，請稍後再試";
          } else if (response.status === 413) {
            this.message = "檔案太大，請將檔案大小壓縮過後再上傳";
          } else {
            this.message = `${response.status} ${response.statusText}`;
          }
        })
        .catch(this.errorHandle);
    }
    // @ts-expect-error: refs not defined
    this.$refs.fileInputElement.value = "";
    this.loading = false;
    // this.updateStatus(GroupStatus.knotbooksigned);
  }

  getReport() {
    this.loading = true;
    FileService.getReport()
      .then((link) => {
        link.click();
        URL.revokeObjectURL(link.href);
        this.docChecked = true;
        this.loading = false;
      })
      .catch(this.errorHandle);
  }

  updateLink() {
    const user = this.$store.state.auth.user;
    if (user && this.projectLoaded) {
      GroupService.Project(OperationMode.modify, this.project)
        .then(() => {
          this.$store.dispatch("auth/update").then(() => {
            this.$emit("update");
          });
          this.linkUpdated = true;
          setTimeout(() => {
            this.$router.go(0);
          }, 3000);
        })
        .catch(this.errorHandle);
    }
  }

  submit() {
    this.loading = true;
    if (this.isAffidavitSigned) {
      GroupService.confirmProject()
        .then(() => {
          return this.updateStatus(GroupStatus.reportuploaded);
        })
        .then(() => {
          this.loading = false;
        })
        .catch(this.errorHandle);
    }
  }

  async updateStatus(status: GroupStatus) {
    return GroupService.updateStatus(status)
      .then(() => {
        return this.$store.dispatch("auth/update");
      })
      .then(() => {
        return this.$emit("update");
      })
      .catch(this.errorHandle);
  }
}
