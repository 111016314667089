import { StoreName } from "./service";
import { IGroup } from "@/store/interfaces-from-server/user/group";
import axios from "axios";
import groupService from "./group-service";

const API_URL = "/api";

class AuthService {
  async login(group: IGroup) {
    await axios.post(API_URL + "/login", {
      email: group.email,
      password: group.password,
    });
    const _group = await groupService.getGroup();
    return _group;
  }

  async logout() {
    Object.values(StoreName).forEach((storeName) => {
      localStorage.removeItem(storeName);
    });
    return axios.get(API_URL + "/logout");
  }

  async register(group: IGroup) {
    return axios.post(API_URL + "/register", {
      email: group.email,
      password: group.password,
      category: group.category,
    });
  }

  async validate(code: string) {
    return axios.post(API_URL + "/validate", {
      verificationcode: code,
    });
  }
}

export default new AuthService();
