
import { Options, prop, Vue } from "vue-class-component";
import { Field, ErrorMessage } from "vee-validate";
import { IMapMeta } from "../../store/interfaces-from-server/meta/map";
import { IQuery } from "../../store/interfaces-from-server/user/query";
import CountyNLSCField from "./field/CountyNLSC.vue";
import TownNLSCField from "./field/TownNLSC.vue";
import SectionNLSCField from "./field/SectionNLSC.vue";
import metaService from "@/services/meta-service";
import { ISectionItem } from "@/store/interfaces/nlsc";

@Options({
  name: "CadastralMapForm",
  components: {
    Field,
    ErrorMessage,
    CountyNLSCField,
    TownNLSCField,
    SectionNLSCField,
  },
  props: {
    selectedMapMeta: prop<IMapMeta>({ required: true }),
    query: prop<IQuery>({ required: true }),
  },
})
export default class CadastralMapForm extends Vue {
  selectedMapMeta: IMapMeta;
  query: IQuery;

  indexReloaded = false;
  coordinates = [{ name: "TWD97(平面坐標)" }, { name: "TWD97(經緯度坐標)" }];
  formats = [{ name: "SHP" }, { name: "DXF" }];

  mounted() {
    this.townCode = this.query.note || "";
  }

  onCountySelected(countyCode: string) {
    this.query.note = countyCode;
    this.countyCode = countyCode;
    this.townCode = "";
    this.query.index = "";
  }

  onTownSelected(townCode: string) {
    this.query.note = this.townCode;
    this.townCode = townCode;
    this.query.index = "";
  }

  onSectionSelected(section: ISectionItem) {
    this.indexReloaded = true;
    this.query.index = section.office[0] + section.sectcode[0];
    this.$nextTick(() => {
      this.indexReloaded = false;
    });
    metaService.getTownName(this.townCode).then((name) => {
      this.query.indexExtra = name + section.sectstr[0];
    });
  }

  countyCode = "";
  townCode = "";
  sectionCode = "";

  get coordValidate() {
    if (this.selectedMapMeta?.needCoord) {
      return this.stringRequired;
    }
    return null;
  }

  stringRequired(value: string) {
    if (!value) {
      return "必填";
    }
    if (typeof value !== "string") {
      return "必須為字串";
    }
    return true;
  }
  numberRequired(value: string) {
    if (!value) {
      return "必填";
    }
    const valueN = parseInt(value);
    if (!valueN) {
      return "必須為數字";
    }
    return true;
  }
}
