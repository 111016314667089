
import { BasicView } from "../../utils/view";
import { Options } from "vue-class-component";
import Modal from "../../components/Modal.vue";
import MapTable from "../../components/map/MapTable.vue";
import FileService from "../../services/file-service";
import { GroupStatus } from "../../store/interfaces-from-server/user/group";
import GroupService from "../../services/group-service";
import { FileType } from "../../store/interfaces-from-server/meta/file";

@Options({
  name: "AffidavitView",
  components: { Modal, MapTable },
  props: {},
  emits: ["update", "to"],
})
export default class AffidavitView extends BasicView {
  uploaded = false;
  docChecked = false;
  fileInput: File | null = null;

  get isMapRequested() {
    if (this.$store.state.auth.user) {
      return this.$store.state.auth.user.status === GroupStatus.maprequested;
    } else {
      return false;
    }
  }

  onFileChange(event: Event) {
    if (event.target) {
      // @ts-expect-error: event target type not defined
      this.fileInput = event.target.files[0] as File;
    }
  }

  mounted() {
    if (!this.isLogIned) {
      this.$router.push({ name: "login" });
    }
    const user = this.$store.state.auth.user;
    if (user?.status !== GroupStatus.maprequested) {
      this.$router.push({ name: "home", query: { t: Date.now() } });
    }
  }

  uploadAffidavit() {
    this.loading = true;
    if (this.fileInput) {
      const formData = new FormData();
      formData.append("files", this.fileInput);
      FileService.postFile(FileType.affidavit, formData)
        .then((response) => {
          if (response.ok) {
            this.message = "";
            this.uploaded = true;
          } else if (response.status === 500) {
            this.message = "伺服器錯誤，請稍後再試";
          } else if (response.status === 413) {
            this.message = "檔案太大，請將檔案大小壓縮過後再上傳";
          } else {
            this.message = `${response.status} ${response.statusText}`;
          }
        })
        .catch(this.errorHandle);
    }
    // @ts-expect-error: refs not defined
    this.$refs.fileInputElement.value = "";
    this.loading = false;
    // this.updateStatus(GroupStatus.knotbooksigned);
  }

  getAffidavit() {
    FileService.getAffidavit()
      .then((link) => {
        link.click();
        URL.revokeObjectURL(link.href);
      })
      .catch(this.errorHandle);
  }

  getGroupAffidavit() {
    this.loading = true;
    FileService.getAffidavit(true)
      .then((link) => {
        link.click();
        URL.revokeObjectURL(link.href);
        this.docChecked = true;
        this.loading = false;
      })
      .catch(this.errorHandle);
  }

  confirm() {
    if (this.isMapRequested) {
      this.updateStatus(GroupStatus.affidavitsigned);
    }
  }

  updateStatus(status: GroupStatus) {
    GroupService.updateStatus(status)
      .then(() => {
        this.$store.dispatch("auth/update").then(() => {
          this.$emit("update");
        });
      })
      .catch(this.errorHandle);
  }
}
