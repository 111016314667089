import {
  ICountyItem,
  ICountyItems,
  ISectionItem,
  ITownItem,
} from "@/store/interfaces/nlsc";
import axios from "axios";
import { parseStringPromise } from "xml2js";

import { MetaType } from "./service";

const API_URL = "/api";
const API_SERVICE = `${API_URL}/meta`;

const NLSC_API = "https://api.nlsc.gov.tw/other";

class MetaService {
  countyItem?: ICountyItem[];
  townItem: { [name: string]: ITownItem[] } = {};
  sectionItem: { [name: string]: ISectionItem[] } = {};

  async get(type: MetaType) {
    const response = await axios.get(API_SERVICE + `/${type}`);
    localStorage.setItem(type, JSON.stringify(response.data));
    return response.data;
  }

  async getListCounty() {
    if (!this.countyItem) {
      const response = await axios.get(NLSC_API + "/ListCounty");
      return parseStringPromise(response.data)
        .then((countyItems) => {
          this.countyItem = countyItems.countyItems.countyItem;
          return this.countyItem;
        })
        .catch((err) => {
          console.log(err);
          return null;
        });
    } else {
      return this.countyItem;
    }
  }

  async getListTown(countyCode: string) {
    if (!this.townItem[countyCode]) {
      const response = await axios.get(NLSC_API + `/ListTown/${countyCode}`);
      return parseStringPromise(response.data)
        .then((townItems) => {
          this.townItem[countyCode] = townItems.townItems.townItem;
          return this.townItem[countyCode];
        })
        .catch((err) => {
          console.log(err);
          return null;
        });
    } else {
      return this.townItem[countyCode];
    }
  }

  async getTownName(townCode: string) {
    const countryList = await this.getListCounty();
    const townList = await this.getListTown(townCode[0]);
    if (countryList && townList) {
      let county: ICountyItem | null = null;
      let town: ITownItem | null = null;
      for (let index = 0; index < countryList.length; index++) {
        if (countryList[index].countycode[0] === townCode[0]) {
          county = countryList[index];
        }
      }
      for (let index = 0; index < townList.length; index++) {
        if (townList[index].towncode[0] === townCode) {
          town = townList[index];
        }
      }
      if (county && town) {
        return county?.countyname[0] + town?.townname[0];
      }
    }
    return "";
  }

  async getListSection(townCode: string) {
    if (!this.sectionItem[townCode]) {
      const response = await axios.get(
        NLSC_API + `/ListLandSection/${townCode[0]}/${townCode}`
      );
      return parseStringPromise(response.data)
        .then((sectionItems) => {
          this.sectionItem[townCode] = sectionItems.sectItems.sectItem;
          return this.sectionItem[townCode];
        })
        .catch((err) => {
          console.log(err);
          return null;
        });
    } else {
      return this.sectionItem[townCode];
    }
  }
}

export default new MetaService();
