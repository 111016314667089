import AuthService from "../../services/auth-service";
import { ActionContext } from "vuex";
import { RootState } from "../vuex";

import GroupService from "../../services/group-service";
import { UserState } from "../classes/group";
import { IGroup } from "../interfaces-from-server/user/group";

const userString = localStorage.getItem("user");
const user = userString ? JSON.parse(userString) : null;

type ActionContextType = ActionContext<UserState, RootState>;

const initialState: UserState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: undefined };

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    update({ commit }: ActionContextType) {
      return GroupService.getGroup().then(
        (user) => {
          commit("updateSuccess", user);
          return Promise.resolve(user);
        },
        (error) => {
          commit("updateFailure");
          return Promise.reject(error);
        }
      );
    },
    login({ commit }: ActionContextType, user: IGroup) {
      return AuthService.login(user).then(
        (user) => {
          commit("loginSuccess", user);
          return Promise.resolve(user);
        },
        (error) => {
          commit("loginFailure");
          return Promise.reject(error);
        }
      );
    },
    logout({ commit }: ActionContextType) {
      AuthService.logout();
      commit("logout");
    },
    register({ commit }: ActionContextType, user: IGroup) {
      return AuthService.register(user).then(
        (response) => {
          commit("registerSuccess");
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("registerFailure");
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    loginSuccess(state: UserState, user: IGroup) {
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailure(state: UserState) {
      state.status.loggedIn = false;
      state.user = undefined;
    },
    logout(state: UserState) {
      state.status.loggedIn = false;
      state.user = undefined;
    },
    registerSuccess(state: UserState) {
      state.status.loggedIn = false;
    },
    registerFailure(state: UserState) {
      state.status.loggedIn = false;
    },
    updateSuccess(state: UserState, user: IGroup) {
      state.user = user;
    },
    updateFailure(state: UserState) {
      state.user = undefined;
    },
  },
};
