
import { Options, prop } from "vue-class-component";
import Modal from "../Modal.vue";
import { BasicView } from "../../utils/view";
import adminService from "@/services/admin-service";
import { GroupStatus, IGroup } from "@/store/interfaces-from-server/user/group";

type IStatus = {
  name: keyof typeof GroupStatus;
};

@Options({
  name: "GroupTable",
  components: { Modal },
  props: {
    category: prop<string>({ required: true }),
    statuses: prop<Array<IStatus>>({}),
  },
  emits: [],
})
export default class StudentTable extends BasicView {
  modalShow = false;
  groups?: IGroup[];
  grouploaded = false;
  category: string;
  statuses: IStatus[];

  get filteredGroups() {
    if (this.groups) {
      const groups = this.groups.filter((group) => {
        return group.category === this.category;
      });

      return groups.filter((group) => {
        return this.statuses.find((status) => status.name === group.status);
      });
    }
    return null;
  }

  mounted() {
    adminService
      .getGroups()
      .then((groups) => {
        this.groups = groups;
        if (this.groups) {
          this.groups.sort((a, b) => a.gid - b.gid);
          this.grouploaded = true;
        }
      })
      .catch(this.errorHandle);
  }
  closeModal() {
    this.modalShow = false;
    // this.$store.dispatch("auth/update");
    this.$router.push({
      name: "groupstatus",
      query: { t: Date.now() },
    });
  }
}
