import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7e42b239"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = {
  key: 1,
  class: "form-group"
}
const _hoisted_3 = {
  class: "alert alert-danger",
  role: "alert"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MapQueryForm = _resolveComponent("MapQueryForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loaded)
      ? (_openBlock(), _createBlock(_component_MapQueryForm, {
          key: 0,
          mode: _ctx.formData.mode,
          query: _ctx.formData.query,
          "onUpdate:query": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.query) = $event)),
          onSubmit: _ctx.handleSubmit,
          onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
        }, null, 8, ["mode", "query", "onSubmit"]))
      : _createCommentVNode("", true),
    (_ctx.message)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.message), 1),
          _createElementVNode("button", {
            type: "button",
            class: "btn btn-lg btn-danger",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close')))
          }, " 取消 ")
        ]))
      : _createCommentVNode("", true)
  ]))
}