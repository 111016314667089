
import { Options } from "vue-class-component";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { BasicView } from "@/utils/view";

@Options({
  name: "LoginView",
  components: { Form, Field, ErrorMessage },
  props: {},
})
export default class LoginView extends BasicView {
  schema = yup.object().shape({
    email: yup
      .string()
      .required("email is required!")
      .email("Email is invalid!"),
    password: yup.string().required("Password is required!"),
  });

  loading = false;

  user = {
    email: "",
    password: "",
  };

  mounted() {
    if (this.isLogIned) {
      this.$router.push("/");
    }
  }

  handleLogin() {
    this.loading = true;
    this.$store
      .dispatch("auth/login", this.user)
      .then(() => {
        this.message = "登入成功";
        this.loading = false;
        setTimeout(() => {
          this.$router.push("/");
        }, 1000);
      })
      .catch((error) => {
        this.message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        this.loading = false;
      });
  }
}
