import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "form-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Form = _resolveComponent("Form", true)!

  return (_openBlock(), _createBlock(_component_Form, { "validation-schema": _ctx.schema }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "field"),
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "submitBtn"),
        _createElementVNode("button", {
          type: "button",
          class: "btn btn-lg btn-warning",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
        }, " 取消 ")
      ])
    ]),
    _: 3
  }, 8, ["validation-schema"]))
}