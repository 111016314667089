import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = {
  key: 0,
  class: "alert alert-danger",
  role: "alert"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: "btn btn-primary btn-lg",
      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.getQueryZip && _ctx.getQueryZip(...args)), ["prevent"]))
    }, " 下載圖資申請清單 "),
    _createElementVNode("div", null, [
      (_ctx.message)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.message), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}