
import { BasicView } from "../../utils/view";
import { Options } from "vue-class-component";
import Modal from "../../components/Modal.vue";
import StudentTable from "../../components/signup/table/StudentTable.vue";
import TeacherTable from "../../components/signup/table/TeacherTable.vue";
import ProjectTable from "../../components/signup/table/ProjectTable.vue";
import GroupService from "../../services/group-service";
import { GroupStatus } from "@/store/interfaces-from-server/user/group";
@Options({
  name: "Groupform",
  components: { Modal, StudentTable, TeacherTable, ProjectTable },
  props: {},
  emits: ["update", "to"],
})
export default class GroupFromView extends BasicView {
  studentComplete = false;
  teacherComplete = false;
  paperComplete = false;

  get isConfirm() {
    const user = this.$store.state.auth.user;
    if (user) {
      return user.status === GroupStatus.filledin;
    } else {
      return false;
    }
  }

  mounted() {
    if (!this.isLogIned) {
      this.$router.push({ name: "login" });
    }
    if (
      this.$store.state.auth.user?.status !== GroupStatus.validated &&
      !this.isConfirm
    ) {
      this.$router.push({ name: "home" });
    }
  }

  confirm() {
    if (this.isConfirm) {
      this.updateStatus(GroupStatus.registered);
    } else {
      this.updateStatus(GroupStatus.filledin);
    }
  }

  cancel() {
    this.updateStatus(GroupStatus.validated);
  }

  updateStatus(status: GroupStatus) {
    return GroupService.updateStatus(status)
      .then(() => {
        this.$store.dispatch("auth/update").then(() => {
          this.$emit("update");
        });
      })
      .catch(this.errorHandle);
  }

  toMap() {
    this.$emit("to", "map");
  }
}
