
import { Options } from "vue-class-component";
import StudentForm from "../../components/signup/form/StudentForm.vue";
import { IStudent } from "../../store/interfaces-from-server/user/member";
import { BasicView } from "../../utils/view";
import { OperationMode } from "../../services/service";
import { Student } from "../../store/classes/member";
import MemberService from "../../services/member-service";

@Options({
  name: "StudentView",
  components: { StudentForm },
  props: {},
})
export default class StudentView extends BasicView {
  formData: {
    mode: OperationMode;
    student: IStudent;
  };

  loaded = false;

  created(): void {
    if (!this.isLogIned) {
      this.$router.push({ name: "login" });
    }
    const mode = this.$route.query.mode as OperationMode;
    this.formData = {
      mode: mode,
      student: new Student(),
    };
    if (mode === "modify" || mode === "delete") {
      const sid = parseInt(this.$route.query.sid as string);
      this.formData.student.sid = sid;
      MemberService.Student(OperationMode.getid, this.formData.student)
        .then((students) => {
          if (students.length > 0) {
            this.formData.student = students[0];
            this.loaded = true;
          }
        })
        .catch(this.errorHandle);
    } else {
      this.loaded = true;
    }
  }

  handleSubmit() {
    MemberService.Student(this.formData.mode, this.formData.student)
      .then(() => this.$emit("close"))
      .catch(this.errorHandle);
  }
}
