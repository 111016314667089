import { GroupStatus, IGroup } from "@/store/interfaces-from-server/user/group";
import { IProject } from "@/store/interfaces-from-server/user/project";
import axios from "axios";

import { OperationMode, RESTfulService } from "./service";

const API_URL = "/api";
const API_SERVICE = `${API_URL}/group`;

class GroupService {
  async getGroup() {
    const response = await axios.get<IGroup>(API_SERVICE);
    localStorage.setItem("user", JSON.stringify(response.data));
    return response.data;
  }

  async Project(mode: OperationMode, project?: IProject) {
    const API_KEYWORD = "project";
    const API_URI = `${API_SERVICE}/${API_KEYWORD}`;
    return RESTfulService(API_URI, mode, project?.pid, project);
  }

  async confirmProject() {
    const API_KEYWORD = "project/confirm";
    const API_URI = `${API_SERVICE}/${API_KEYWORD}`;
    return axios.get(API_URI);
  }

  async updateStatus(status: GroupStatus) {
    return axios.put(API_SERVICE + `/status`, { status: status });
  }
}

export default new GroupService();
