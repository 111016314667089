import { createStore } from "vuex";
import { auth } from "./modules/auth";
import { selection } from "./modules/selection";

const store = createStore({
  modules: {
    auth,
    selection,
  },
});

export default store;
