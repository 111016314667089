
import { Options, prop, Vue } from "vue-class-component";
import { Field, ErrorMessage } from "vee-validate";
import { IApi, IMapMeta } from "../../store/interfaces-from-server/meta/map";
import { IQuery } from "../../store/interfaces-from-server/user/query";
import metaService from "../../services/meta-service";
import { MetaType } from "../../services/service";

@Options({
  name: "APIForm",
  components: { Field, ErrorMessage },
  props: {
    selectedMapMeta: prop<IMapMeta>({ required: true }),
    query: prop<IQuery>({ required: true }),
  },
})
export default class APIForm extends Vue {
  selectedMapMeta: IMapMeta;
  query: IQuery;

  apiMetas: IApi[];
  apiMetaLoaded = false;

  mounted() {
    metaService.get(MetaType.api).then((metas) => {
      this.apiMetas = metas;
      this.apiMetaLoaded = true;
    });
  }

  getApiFromCode(code: string) {
    for (let index = 0; index < this.apiMetas.length; index++) {
      const meta: IApi = this.apiMetas[index];
      if (meta.apicode === code) {
        return meta;
      }
    }
  }

  set index(code: string) {
    this.query.index = code;
    this.query.indexExtra = this.getApiFromCode(code)?.name;
  }
  get index() {
    return this.query.index || "";
  }

  stringRequired(value: string) {
    if (!value) {
      return "必填";
    }
    return true;
  }
}
