
import { Options, Vue } from "vue-class-component";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { GroupCategory } from "@/store/interfaces-from-server/user/group";

@Options({
  name: "Register",
  components: { Form, Field, ErrorMessage },
  props: {},
})
export default class LoginView extends Vue {
  schema = yup.object().shape({
    email: yup
      .string()
      .required("email is required!")
      .email("Email is invalid!"),
    category: yup
      .mixed<GroupCategory>()
      .oneOf(Object.values(GroupCategory))
      .required(),
    password: yup
      .string()
      .required("Password is required!")
      .min(1, "Must be at least 1 characters!"),
    passwordConfirmed: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });

  loading = false;
  successful = false;
  message = "";

  user = {
    email: "",
    password: "",
    category: "",
  };

  get isLogIned() {
    return this.$store.state.auth.status.loggedIn;
  }

  mounted() {
    if (this.isLogIned) {
      this.$router.push("/");
    }
  }

  handleRegister() {
    this.loading = true;
    this.$store
      .dispatch("auth/register", this.user)
      .then(() => {
        this.message = "註冊成功！ 3 秒後導向登入頁面";
        this.successful = true;
        this.loading = false;
        setTimeout(() => {
          this.$router.push("/login");
        }, 2000);
      })
      .catch((error) => {
        this.message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        this.successful = false;
        this.loading = false;
      });
  }
}
