
import { Options, prop, Vue } from "vue-class-component";
import { Field, ErrorMessage } from "vee-validate";
import { IMapMeta } from "../../store/interfaces-from-server/meta/map";
import { IQuery } from "../../store/interfaces-from-server/user/query";

@Options({
  name: "SingleMapFrom",
  components: { Field, ErrorMessage },
  props: {
    selectedMapMeta: prop<IMapMeta>({ required: true }),
    query: prop<IQuery>({ required: true }),
  },
})
export default class SingleMapFrom extends Vue {
  selectedMapMeta: IMapMeta;
  query: IQuery;

  mounted() {
    this.query.scale = "5000";
  }

  stringRequired(value: string) {
    if (!value) {
      return "必填";
    }
    if (typeof value !== "string") {
      return "必須為字串";
    }
    return true;
  }
}
