
import { Options, prop, Vue } from "vue-class-component";
import { Field, ErrorMessage } from "vee-validate";
import { IMapMeta } from "../../store/interfaces-from-server/meta/map";
import { IQuery } from "../../store/interfaces-from-server/user/query";
// import metaService from "../../services/meta.service";
// import { MetaType } from "../../services/service";

@Options({
  name: "Road3DForm",
  components: { Field, ErrorMessage },
  props: {
    selectedMapMeta: prop<IMapMeta>({ required: true }),
    query: prop<IQuery>({ required: true }),
  },
})
export default class Road3DForm extends Vue {
  selectedMapMeta: IMapMeta;
  query: IQuery;

  roads = [
    { name: "國道" },
    { name: "快速道路" },
    { name: "臺中市" },
    { name: "高雄市" },
  ];

  stringRequired(value: string) {
    if (!value) {
      return "必填";
    }
    return true;
  }
}
