
import { Options } from "vue-class-component";
import ProjectForm from "../../components/signup/form/ProjectForm.vue";
import { IProject } from "../../store/interfaces-from-server/user/project";
import { BasicView } from "../../utils/view";
import { OperationMode } from "../../services/service";
import { Project } from "../../store/classes/group";
import GroupService from "../../services/group-service";
import { GroupCategory } from "@/store/interfaces-from-server/user/group";

@Options({
  name: "ProjectView",
  components: { ProjectForm },
  props: {},
})
export default class ProjectView extends BasicView {
  formData: {
    mode: OperationMode;
    project: IProject;
  };

  loaded = false;

  created(): void {
    if (!this.isLogIned) {
      this.$router.push({ name: "login" });
    }
    const mode = this.$route.query.mode as OperationMode;

    this.formData = {
      mode: mode,
      project: new Project(),
    };
    if (mode === "modify" || mode === "delete") {
      const pid = parseInt(this.$route.query.pid as string);
      this.formData.project.pid = pid;
      GroupService.Project(OperationMode.getid, this.formData.project)
        .then((project) => {
          this.formData.project = project;
          this.loaded = true;
        })
        .catch(this.errorHandle);
    } else {
      const user = this.$store.state.auth.user;
      if (user) {
        let groupCategoryCode = "";
        if (user.category === GroupCategory.highschool) {
          groupCategoryCode = "HS";
        } else {
          groupCategoryCode = "CO";
        }
        this.formData.project.pcode = `${groupCategoryCode}005${user.gid
          .toString()
          .padStart(4, "0")}`;
      }
      this.loaded = true;
    }
  }

  handleSubmit() {
    GroupService.Project(this.formData.mode, this.formData.project)
      .then(() => this.$emit("close"))
      .catch(this.errorHandle);
    this.loaded = false;
  }
}
