import {
  IMember,
  IStudent,
  ITeacher,
} from "../interfaces-from-server/user/member";

export class Member implements IMember {
  name: string;
  email: string;
  phone: string;
  school: string;
  subject: string;
}

export class Student extends Member implements IStudent {
  sid: number;
  gid: number;
  year: string;
  leader: boolean;
}

export class Teacher extends Member implements ITeacher {
  tid: number;
}

export function StudentFactory(_student: Student) {
  const student = new Student();
  student.sid = _student.sid;
  student.name = _student.name;
  student.email = _student.email;
  student.phone = _student.phone;
  student.school = _student.school;
  student.subject = _student.subject;
  student.year = _student.year;
  student.leader = _student.leader;
  return student;
}

export function TeacherFactory(_teacher: Teacher) {
  const teacher = new Teacher();
  teacher.tid = _teacher.tid;
  teacher.name = _teacher.name;
  teacher.email = _teacher.email;
  teacher.phone = _teacher.phone;
  teacher.school = _teacher.school;
  teacher.subject = _teacher.subject;
  return teacher;
}
