import { FileType } from "../store/interfaces-from-server/meta/file";
import axios from "axios";

const API_URL = "/api";
const API_SERVICE = `${API_URL}/file`;

class FileService {
  async getAffidavit(isGroup = false) {
    return axios
      .get(API_SERVICE + `/affidavit${isGroup ? "/group" : ""}`, {
        responseType: "blob",
      })
      .then((response) => {
        const filename = (
          response.headers["content-disposition"] || "圖資使用切結書.docx"
        ).split("filename=")[1];
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        return link;
      });
  }
  async getReport() {
    return axios
      .get(API_SERVICE + "/report", {
        responseType: "blob",
      })
      .then((response) => {
        const filename = (
          response.headers["content-disposition"] || "report.pdf]"
        ).split("filename=")[1];
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        return link;
      });
  }

  async postFile(fileType: FileType, formData: FormData) {
    return fetch(API_SERVICE + `/${fileType}`, {
      method: "POST",
      body: formData,
    });
  }
}

export default new FileService();
