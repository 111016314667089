
import { Options, prop, Vue } from "vue-class-component";
import { Field, ErrorMessage } from "vee-validate";
import { ISectionItem } from "@/store/interfaces/nlsc";
import metaService from "@/services/meta-service";

@Options({
  name: "SectionNLSCField",
  components: { Field, ErrorMessage },
  props: {
    townCode: prop<string>({ required: true }),
    sectionCode: prop<string>({ required: true }),
  },
  emits: ["sectionSelected"],
})
export default class SectionNLSCField extends Vue {
  sectionList?: ISectionItem[] | null;
  sectionListLoaded = false;
  sectionCode: string;
  townCode: string;

  mounted() {
    metaService.getListSection(this.townCode).then((sectionItems) => {
      this.sectionList = sectionItems;
      this.sectionListLoaded = true;
    });
  }

  sectionSelected = false;
  set selectedSection(code: string) {
    if (this.sectionList) {
      for (let index = 0; index < this.sectionList.length; index++) {
        const section = this.sectionList[index];
        const _code = section.office[0] + section.sectcode[0];
        if (_code === code) {
          this.$emit("sectionSelected", section);
        }
      }
    }
  }
  get selectedSection() {
    // console.log(`section Field code: ${this.sectionCode}`);
    return this.sectionCode as string;
  }
}
