
import { Options, prop, Vue } from "vue-class-component";
import { Field, ErrorMessage } from "vee-validate";
import { IMapMeta } from "../../store/interfaces-from-server/meta/map";
import { IQuery } from "../../store/interfaces-from-server/user/query";

@Options({
  name: "YearMapFrom",
  components: { Field, ErrorMessage },
  props: {
    selectedMapMeta: prop<IMapMeta>({ required: true }),
    query: prop<IQuery>({ required: true }),
  },
})
export default class YearMapFrom extends Vue {
  selectedMapMeta: IMapMeta;
  query: IQuery;

  get isTopo() {
    return this.query.type === "地形圖（不含正射影像及等高線）";
  }

  mounted() {
    this.query.scale = this.selectedMapMeta.fixScale;
    this.query.format = this.query.format || this.selectedMapMeta.fixFormat;
  }

  stringRequired(value: string) {
    if (!value) {
      return "必填";
    }
    if (typeof value !== "string") {
      return "必須為字串";
    }
    return true;
  }
}
